<template>
  <div class="sidebar-right d-flex flex-column">
    <form @submit="submit">
      <div class="sidebar-right-header d-flex align-items-center justify-content-between">
        <h3 class="filter-title text-color-gray mb-0 pb-0">FILTER</h3>
        <div class="icon text-color-primary">
          <ph-info weight="bold" :size="22" />
        </div>
      </div>
      <div class="sidebar-right-content">
        <div class="form-group">
          <label for="supplier">Lieferant</label>
          <multi-select
              placeholder="Suchbegriff eingeben"
              :multiple="false"
              :loading="suppliers.loading"
              :searchable="true"
              :showLabels="false"
              open-direction="bottom"
              v-model="suppliers.selected"
              :options="suppliers.options"
              :internal-search="false"
              :clear-on-select="false"
              :close-on-select="true"
              @select="selectOptionSupplier"
              :options-limit="300"
              :limit="3"
              :max-height="600"
              :show-no-results="true"
              :hide-selected="true"
              @search-change="searchSupplier"
            >
            <template slot="noOptions">
                <div class="option-list-item">
                  <span>Keine Einträge</span>
                </div>
              </template>
              <template slot="singleLabel" slot-scope="props"
                ><span class="selected-option"> {{ props.option.supplierId }} {{
                  props.option.fullName
                }}</span></template
              >
              <template slot="noResult">
                <div class="option-list-item">
                  <span>No Result</span>
                </div>
              </template>
              <template slot="option" slot-scope="props">
                <div class="option-list-item">
                  <span>{{ props.option.supplierId }} {{  props.option.fullName }}</span>
                </div>
              </template>
            </multi-select>
        </div>
        <div class="form-group mt-3">
          <label for="model">Modell</label>
          <multi-select
              @close="resetPointer"
              ref="modelsSelect"
              placeholder="Suchbegriff eingeben"
              :multiple="false"
              :loading="models.loading"
              :searchable="true"
              :showLabels="false"
              v-model="models.selected"
              :options="models.options"
              :internal-search="false"
              :clear-on-select="false"
              :close-on-select="true"
              @select="selectModel"
              :options-limit="300"
              :limit="3"
              :max-height="600"
              :show-no-results="true"
              :hide-selected="true"
              @search-change="searchModel"
            >
              <template slot="singleLabel" slot-scope="props"
                ><span class="selected-option"> {{props.option.modelId}} {{
                  props.option.name
                }}</span></template
              >
              <template slot="noResult">
                <div class="option-list-item">
                  <span>No Result</span>
                </div>
              </template>
              <template slot="noOptions">
                <div class="option-list-item">
                  <span>Keine Einträge</span>
                </div>
              </template>
              <template slot="option" slot-scope="props">
                <div class="option-list-item">
                  <span> {{props.option.modelId}} {{ props.option.name }}</span>
                </div>
              </template>
              <template
                v-if="models.page !== paginator.totalPages && models.options.length >= 10"
                slot="afterList"
              >
                <a class="load-more-btn" @click="loadMoreModels" href="#"
                  >Mehr Laden</a
                >
              </template>
          </multi-select>
        </div>
        <div class="form-group mt-3">
          <label for="goodsGroup">Warengruppe</label>
          <multi-select
              placeholder="Suchbegriff eingeben"
              :multiple="false"
              :loading="goodsGroups.loading"
              :searchable="true"
              :showLabels="false"
              open-direction="bottom"
              v-model="goodsGroups.selected"
              :options="goodsGroups.options"
              :internal-search="false"
              :clear-on-select="false"
              :close-on-select="true"
              @select="selectGoodsGroup"
              :options-limit="300"
              :limit="3"
              :max-height="600"
              :show-no-results="true"
              :hide-selected="true"
              @search-change="searchGoodsgroup"
            >
            <template slot="noOptions">
                <div class="option-list-item">
                  <span>Keine Einträge</span>
                </div>
              </template>
              <template slot="singleLabel" slot-scope="props"
                ><span class="selected-option"> {{ props.option.goodsGroup }} {{
                  props.option.fullName
                }}</span></template
              >
              <template slot="noResult">
                <div class="option-list-item">
                  <span>No Result</span>
                </div>
              </template>
              <template slot="option" slot-scope="props">
                <div class="option-list-item">
                  <span>{{ props.option.goodsGroup }}</span>
                </div>
              </template>
              <template
                v-if="goodsGroups.page !== paginator.totalPages && goodsGroups.options.length >= 10"
                slot="afterList"
              >
                <a class="load-more-btn" @click="loadMoreGoodsGroup" href="#"
                  >Mehr Laden</a
                >
              </template>
          </multi-select>
        </div>
        <div class="form-group mt-3">
          <label for="supplier">Anwendungsszenarien</label>
          <multi-select
            placeholder="Suchbegriff eingeben"
            :multiple="true"
            :loading="pricelist.loading"
            :searchable="true"
            :showLabels="false"
            open-direction="bottom"
            v-model="pricelist.selected"
            :options="pricelist.options"
            :internal-search="false"
            :clear-on-select="false"
            :close-on-select="true"
            @select="selectPl"
            :options-limit="300"
            :max-height="600"
            :show-no-results="true"
            :hide-selected="true"
            @search-change="searchPriceList"
            label="name"
            :class="{'anwend' : pricelist.selected.length }"
          >
          <template slot="noOptions">
              <div class="option-list-item">
                <span>Keine Einträge</span>
              </div>
            </template>
            <template slot="noResult">
              <div class="option-list-item">
                <span>No Result</span>
              </div>
            </template>
            <template v-slot:tag="{ option }">
              <span class="custom-tag">
                <span class="txt">{{ option.name }} </span>
                <span class="remove" @click="pricelist.selected = pricelist.selected.filter(f => f._id !== option._id)">
                  &times;
                </span>
              </span>
            </template>
          </multi-select>
        </div>
        <div class="form-group mt-5 pb-3 border-bottom-1 border-color-gray-15" >
          <label class="b-contain ps-0 d-flex justify-content-between">
            <span>Inkl. inaktive Modelle</span>
            <input type="checkbox" name="modelStatus" v-model="query.modelStatus" @change="onQchange"/>
            <div class="b-input"></div>
          </label>
        </div>
        <div class="form-group mt-3 pb-3 border-bottom-1 border-color-gray-15" >
          <label class="b-contain ps-0 d-flex justify-content-between">
            <span> Modelle mit PREISLISTEN</span>
            <input type="checkbox" name="hasSPCZFile" v-model="query.hasSPCZFile" @change="onQchange"/>
            <div class="b-input"></div>
          </label>
        </div>
        <div class="form-group mt-3">
          <label class="b-contain ps-0 d-flex justify-content-between">
            <span>Nur Exklusiv-Modelle</span>
            <input type="checkbox" name="isExclusive" v-model="query.isExclusive" @change="onQchange"/>
            <div class="b-input"></div>
          </label>
        </div>
        <div class="form-group mt-5">
          <label class="b-contain ps-0">
            <span>Änderungen Preislisten</span>
          </label>
          <date-picker
            class="mt-2"
            placeholder="dd.mm.yyyy"
            format="DD.MM.YYYY"
            :formatter="myFormat"
            v-model="query.changedSince"
            valueType="format"
          ></date-picker>
        </div>
      </div>
      <div class="sidebar-right-footer mt-auto d-inline-flex flex-column align-items-center">
        <button class="btn btn-primary w-100 btn-default text-fteen" type="submit">Suchen</button>
        <a href="#" class="d-block text-fteen" @click="reset">Filter zurücksetzen</a>
      </div>
    </form>
  </div>
</template>

<script>
import debounce from 'debounce'
import MultiSelect from 'vue-multiselect'
import { PhInfo } from 'phosphor-vue'
import { mapGetters, mapMutations, mapActions } from 'vuex'
import DatePicker from 'vue2-datepicker'

export default {
  props: {
    path: {
      type: String
    }
  },
  data () {
    return {
      paginator: {},
      query: {},
      models: {},
      suppliers: {},
      pricelist: {},
      goodsGroups: {},
      myFormat: {
        // Date to String
        stringify: date => {
          return date ? this.$moment(date).format('DD.MM.YYYY') : null
        },
        // String to Date
        parse: value => {
          return value ? this.$moment(value, 'DD/MM/YYYY').toDate() : null
        }
      }
    }
  },
  components: { PhInfo, MultiSelect, DatePicker },
  computed: {
    ...mapGetters(['getCount', 'getQuery', 'getModels', 'getSuppliers', 'getGoodsgroups', 'getPriceList']),
    isFilter () {
      const x = this.suppliers.selected === null && this.models.selected === null && this.goodsGroups.selected === null
      return x && !this.query.modelStatus && !this.query.isExclusive && !this.query.hasSPCZFile && !this.query.changedSince
    }
  },
  created () {
    this.query = this.getQuery
    this.models = this.getModels
    this.suppliers = this.getSuppliers
    this.goodsGroups = this.getGoodsgroups
    this.pricelist = this.getPriceList
  },
  methods: {
    ...mapMutations(['SET_QUERY', 'SET_MODELS', 'SET_SUPPLIERS', 'SET_GOODSGROPUS', 'SET_PRICELIST', 'SET_VERKAUF']),
    ...mapActions(['incrementCount']),
    isSupplierQuery () {
      return this.suppliers.selected?.supplierId &&
            !this.query.modelStatus &&
            !this.query.isExclusive &&
            !this.query.hasSPCZFile &&
            !this.query.changedSince &&
            !this.models.selected?.modelId &&
            !this.goodsGroups.selected?.goodsGroup &&
            this.pricelist.selected?.length === 0
    },
    isModelQuery () {
      return this.models.selected?.modelId &&
            !this.query.changedSince
    },
    searchSupplier: debounce(function (query) {
      this.fetchSuppliers(query)
    }, 500),
    searchModel: debounce(function (query) {
      this.models.page = 1
      this.models.query = query
      this.fetchModels(false, query)
    }, 500),
    searchGoodsgroup: debounce(function (query) {
      this.goodsGroups.page = 1
      this.goodsGroups.query = query
      this.fetchGoodsgroup(false, query)
    }, 500),
    searchPriceList: debounce(function (query) {
      this.fetchPriceList(query)
    }, 500),
    async fetchModels (load = false, query = '') {
      try {
        let filter = { modelStatus: 'all' }
        if (this.suppliers.selected?.supplierId) {
          filter = { ...filter, supplierId: this.suppliers.selected?.supplierId }
        }
        const payload = {
          limit: 20,
          search: query,
          page: this.models.page,
          filter: JSON.stringify(filter)
        }
        this.models.loading = true
        const response = await this.$api.filterModel(payload)

        this.models.paginator = response.data.data.paginator
        if (!load) {
          this.models.options = response.data.data.items
        }
        if (load) {
          response.data.data.items.map((item) => {
            this.models.options.push({
              ...item
            })
          })
        }
      } catch (e) {
        console.error(e)
      } finally {
        this.models.loading = false
      }
    },
    async fetchSuppliers (query) {
      try {
        if (query.length < 3) {
          this.suppliers.loading = false
          return
        }
        this.suppliers.loading = true
        const data = {
          limit: 50,
          keyw: query,
          page: this.suppliers.page,
          filter: JSON.stringify({ modelStatus: 'all' })
        }
        const response = await this.$api.searchSupplier(data)
        this.suppliers.options = []
        response.data.data.items.map((item) => {
          if (item.fullName) {
            this.suppliers.options.push({
              ...item
            })
          }
        })
      } catch (e) {
        console.error(e)
      } finally {
        this.suppliers.loading = false
      }
    },
    async fetchGoodsgroup (load = false, query) {
      try {
        this.goodsGroups.loading = true
        let data = {
          limit: 10,
          goodsGroupQuery: query,
          page: this.goodsGroups.page
        }
        if (this.suppliers.selected?.supplierId) {
          data = { ...query, supplierQuery: this.suppliers.selected?.supplierId }
        }
        const response = await this.$api.getGoodsGroup(data)
        this.goodsGroups.paginator = response.data.data.paginator

        if (!load) {
          const arr = []
          response.data.data.items.map((item) => {
            arr.push({ goodsGroup: item.goodsGroup.split(' ')[0] })
          })
          this.goodsGroups.options = arr
        }
        if (load) {
          response.data.data.items.map((item) => {
            if (item.goodsGroup) {
              this.goodsGroups.options.push({
                goodsGroup: item.goodsGroup.split(' ')[0]
              })
            }
          })
        }
      } catch (e) {
        console.error(e)
      } finally {
        this.goodsGroups.loading = false
      }
    },
    async fetchPriceList (query = '') {
      try {
        this.pricelist.loading = true
        let data = {
          limit: 10,
          keyw: query,
          page: this.pricelist.page
        }
        if (this.getSuppliers.selected?.supplierId) data = { ...data, supplierId: this.getSuppliers.selected?.supplierId }
        const response = await this.$api.getPreislistenmerkers(data)
        this.pricelist.options = []
        response.data.data.items.map((item) => {
          if (item.name) {
            this.pricelist.options.push({
              ...item
            })
          }
        })
      } catch (e) {
        console.error(e)
      } finally {
        this.pricelist.loading = false
      }
    },
    selectOptionSupplier (selectedOption) {
      this.models.page = 1
      this.models.options = []
      this.models.paginator = {}
      this.suppliers.options = []
      this.models.selected = null
      this.suppliers.selected = selectedOption
      this.SET_SUPPLIERS(this.suppliers)
      this.fetchModels()
      this.fetchPriceList()
    },
    selectModel (selectedOption) {
      this.models.selected = selectedOption
      this.SET_MODELS(this.models)
    },
    selectGoodsGroup (selectedOption) {
      this.goodsGroups.selected = selectedOption
      this.SET_GOODSGROPUS(this.goodsGroups)
    },
    selectPl (opt) {
      this.pricelist.selected = opt
      this.SET_PRICELIST(this.pricelist)
    },
    onQchange () {
      this.SET_QUERY(this.query)
    },
    resetPointer () {
      this.$refs.modelsSelect.pointer = 0
      if (this.$refs.modelsSelect.$refs.list) {
        this.$refs.modelsSelect.$refs.list.scrollTop = 0
      }
    },
    loadMoreModels () {
      this.models.page++
      this.fetchModels(true, this.models.query)
    },
    loadMoreGoodsGroup () {
      this.goodsGroups.page++
      this.fetchGoodsgroup(true, this.goodsGroups.query)
    },
    submit: function (e) {
      e.preventDefault()
      this.incrementCount()

      const modelStatus = this.query.modelStatus ? 'all' : 'active'
      if (this.isModelQuery()) {
        let query = { seller: this.models.selected.supplierName, id: this.models.selected.supplierId, modelId: this.models.selected.modelId }
        if (this.query.isExclusive) query = { ...query, isExclusive: this.query.isExclusive }
        if (this.query.hasSPCZFile) query = { ...query, hasSPCZFile: this.query.hasSPCZFile }
        if (this.query.changedSince) query = { ...query, changedSince: this.query.changedSince }
        query = { ...query, modelStatus: modelStatus, c: this.getCount }
        if (this.goodsGroups.selected?.goodsGroup) query = { ...query, goodsGroup: this.goodsGroups.selected?.goodsGroup }
        this.$router
          .push({
            query,
            path: `/${this.path}/models`
          })
      } else if (this.isSupplierQuery()) {
        this.$router
          .push({
            path: `/${this.path}`,
            query: { supplierId: this.suppliers.selected?.supplierId, modelStatus: this.query.modelStatus ? 'all' : 'active', c: this.getCount }
          })
      } else {
        if (this.pricelist.selected?.length > 0) {
          this.$router.push({ path: '/search', query: { supplier: 'Anwendungsszenarien', modelStatus: modelStatus, pl: '_', c: this.getCount } })
        } else if (!this.isFilter) {
          let supplierName = this.query.changedSince ? 'ÄNDERUNGEN PREISLISTEN' : 'filter'
          if (this.suppliers.selected) {
            supplierName = this.suppliers.selected.fullName
          } else if (this.models.selected) {
            supplierName = this.models.selected.supplierName
          }
          const einkaufRoutes = ['einkauf', 'buyerModels', 'buyerCatalogDetail']
          this.SET_VERKAUF(!einkaufRoutes.includes(this.$route.name))
          this.$router
            .push({
              path: '/search',
              query: { supplier: supplierName, modelStatus: modelStatus, c: this.getCount }
            })
        } else {
          this.$router.push({ path: '/search', query: { supplier: 'Anwendungsszenarien', modelStatus: modelStatus, c: this.getCount } })
        }
      }
    },
    reset: function (e) {
      e.preventDefault()
      this.query = {
        isExclusive: false,
        modelStatus: false,
        hasSPCZFile: false,
        changedSince: ''
      }
      this.models = {
        page: 1,
        query: '',
        options: [],
        paginator: {},
        loading: false,
        selected: null
      }
      this.suppliers = {
        page: 1,
        options: [],
        paginator: {},
        loading: false,
        selected: null
      }
      this.goodsGroups = {
        page: 1,
        query: '',
        options: [],
        paginator: {},
        loading: false,
        selected: null
      }
      this.pricelist = {
        page: 1,
        options: [],
        paginator: {},
        loading: false,
        selected: []
      }
      this.SET_QUERY(this.query)
      this.SET_MODELS(this.models)
      this.SET_SUPPLIERS(this.suppliers)
      this.SET_GOODSGROPUS(this.goodsGroups)
      this.SET_PRICELIST(this.pricelist)
    }
  },
  mounted () {
    this.pageName = this.$route.name
  }
}
</script>
<style lang="scss">
.filter-title {
  font-weight: 500;
  font-size: 18px;
}
.anwend {
  .multiselect__tags {
    padding: 8px 40px 8px 8px;
  }
}
.custom-tag {
  background-color: #EE7F00;
  border-radius: 8px;
  padding: 0px 5px;
  font-size: 12px;
  color: white;
  margin-right: 4px;
  display: inline-flex;
  gap: 4px;
  .txt {
    max-width: 130px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .remove {
    cursor: pointer;
    margin-left: 5px;
  }
}
</style>
